/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ParseContent from 'components/shared/ParseContent'
import parse from 'html-react-parser'
import Plaatjie from '@ubo/plaatjie'

// Language
import { getLanguage } from 'services/language'

// Icons
import Logo from 'img/logo.inline.svg'

// Socials
import Facebook from 'img/icon/facebook.inline.svg'
import LinkedIn from 'img/icon/linkedin.inline.svg'
import Twitter from 'img/icon/twitter.inline.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.background};
  display: ${(props) => (props.noFooter ? 'block' : 'none')};
`

const LogoLink = styled(Link)`
  font-size: 0;
`

const StyledLogo = styled(Logo)`
  height: 45px;
  width: 196px;
`

const Slogan = styled.div`
  font-weight: ${(props) => props.theme.font.weight.s};
  color: ${(props) => props.theme.color.text.light};

  & strong {
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xxl};
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.l};
  }
`

const SocialLink = styled.a`
  font-size: 0;
`

const SocialIcon = styled.div`
  & > svg {
    @media (min-width: 992px) {
      height: 45px;
      width: 45px;
    }

    @media (max-width: 991px) {
      height: 30px;
      width: 30px;
    }
  }
`

const BottomFooter = styled.div`
  @media (min-width: 1200px) {
    max-width: 1440px;
  }
  display: ${(props) => (props.noFooter ? 'block' : 'none')};
`

const BrandLogos = styled.div`
  background-color: ${(props) => props.theme.color.light};
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
`

const BrandLink = styled.a`
  font-size: 0;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const BrandLogo = styled(Plaatjie)`
  height: 55px;
  width: auto;

  & img {
    object-fit: contain !important;
  }
`

const Stripe = styled.div`
  background-color: ${(props) => props.theme.color.contrast};
  position: absolute;
  left: 0;
  right: 0;
  top: 2px;
  height: 2px;
  z-index: 1;
`

const Description = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 2;
`

const Downloads = styled.div`
  background-color: ${(props) => props.theme.color.light};

  & a {
    font-size: ${(props) => props.theme.font.size.s};
    color: ${(props) => props.theme.color.text.main};

    @media (max-width: 991px) {
      width: 100%;
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`

const Footer = ({ noFooter = true }) => {
  const language = getLanguage()

  const { footerNL, footerEN } = useStaticQuery(graphql`
    {
      footerNL: wordpressWpComponenten(
        slug: { eq: "footer" }
        wpml_current_locale: { eq: "nl_NL" }
      ) {
        ...footerContent
      }

      footerEN: wordpressWpComponenten(
        slug: { eq: "footer" }
        wpml_current_locale: { eq: "en_US" }
      ) {
        ...footerContent
      }
    }

    fragment footerContent on wordpress__wp_componenten {
      acf {
        top {
          slogan
          socials {
            title
            url
          }
        }

        middle {
          menu_1 {
            title
            links {
              page {
                title
                url
                target
              }
            }
          }

          menu_2 {
            title
            links {
              page {
                title
                url
                target
              }
            }
          }

          menu_3 {
            title
            links {
              page {
                title
                url
                target
              }
            }
          }

          contact {
            title
            description
          }
        }

        bottom {
          logos {
            title
            link {
              url
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 200)
                }
              }
            }
          }
          description
        }

        downloads {
          file {
            title
            url {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const footerContent = language === 'nl_NL' ? footerNL : footerEN

  return (
    <>
      <StyledFooter noFooter={noFooter}>
        <div className="container">
          <div className="py-5 py-lg-3 d-lg-flex w-100 align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <LogoLink to="/">
                <StyledLogo />
                Home
              </LogoLink>
              <Slogan className="ml-4">
                {parse(footerContent.acf.top.slogan)}
              </Slogan>
            </div>
            <div className="d-flex align-items-center mt-3 mt-lg-0">
              {footerContent.acf.top.socials.map(({ title, url }) => {
                let icon = null

                switch (title) {
                  case 'Twitter':
                    icon = <Twitter />
                    break

                  case 'LinkedIn':
                    icon = <LinkedIn />
                    break

                  default:
                    icon = <Facebook />
                    break
                }

                return (
                  <SocialLink
                    href={url}
                    target="_blank"
                    rel="noreferrer noopener"
                    key={title}
                    className="mr-2 mr-lg-0 ml-lg-2 d-sm-flex d-none"
                  >
                    <SocialIcon>{icon}</SocialIcon>
                    {title}
                  </SocialLink>
                )
              })}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6 col-lg-3">
              <Menu menu={footerContent.acf.middle.menu_1} />
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-md-0">
              <Menu menu={footerContent.acf.middle.menu_2} />
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-md-3 mt-lg-0">
              <Menu menu={footerContent.acf.middle.menu_3} withTips />
            </div>
            <div className="col-md-6 col-lg-3 mt-4 mt-md-3 mt-lg-0">
              <Menu menu={footerContent.acf.middle.contact} />
            </div>
          </div>
        </div>
        <BottomFooter noFooter={noFooter} className="container">
          <BrandLogos className="px-4 py-4 mt-4 my-1 d-md-flex align-items-center justify-content-center justify-content-md-between">
            {footerContent.acf.bottom.logos.map(({ title, image, link }) => {
              if (link && link.url) {
                return (
                  <BrandLink
                    className="d-md-block d-flex pb-md-0 pb-4 justify-content-center"
                    key={title}
                    href={link.url}
                    target="_blank"
                  >
                    <BrandLogo
                      image={image}
                      alt={title}
                      style={{ backgroundSize: 'contain' }}
                    />
                    {title}
                  </BrandLink>
                )
              }

              return (
                <BrandLogo
                  image={image}
                  alt={title}
                  style={{ backgroundSize: 'contain' }}
                />
              )
            })}
          </BrandLogos>
        </BottomFooter>
      </StyledFooter>
      <BottomFooter noFooter={noFooter} className="container py-3">
        <div className="mt-n4 d-flex align-items-center justify-content-end mx-4 position-relative">
          <Stripe />
          <Description className="font-weight-s mt-n2 pl-3">
            {footerContent.acf.bottom.description}
          </Description>
        </div>
        <Downloads className="downloads px-4 py-3 position-relative">
          <div className="col-lg-8 py-2 d-flex flex-wrap justify-content-between">
            {footerContent.acf.downloads.map(({ file }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <a
                href={file.url.localFile.publicURL}
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                {file.title}
              </a>
            ))}
          </div>
        </Downloads>
      </BottomFooter>
    </>
  )
}

const Title = styled.h3`
  font-weight: ${(props) => props.theme.font.weight.xl};
  font-size: ${(props) => props.theme.font.size.m};
  color: ${(props) => props.theme.color.text.contrast};

  &:after {
    background-color: ${(props) => props.theme.color.text.contrast};
    content: '';
    height: 2px;
    width: 60%;
    display: block;
    margin-top: 15px;
  }
`

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`

const Li = styled.li`
  margin: 0;

  & > a {
    font-weight: ${(props) => props.theme.font.weight.s};
    color: ${(props) => props.theme.color.text.main};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.s};
  color: ${(props) => props.theme.color.text.main};

  & a {
    font-weight: ${(props) => props.theme.font.weight.s};
    color: ${(props) => props.theme.color.text.main};
    padding: 0;
    display: inline-block;
  }

  & strong {
    font-weight: ${(props) => props.theme.font.weight.l};
  }
`

const Menu = ({ menu, withTips }) => (
  <>
    <Title>{menu.title}</Title>

    {menu.links && (
      <List className="mt-3">
        {menu.links.map(({ page: { title, url, target } }) => (
          <Li key={title} className="mt-2">
            <Link to={url} target={target}>
              {parse(title)}
            </Link>
          </Li>
        ))}
        {withTips && (
          <Li className="mt-2">
            <a
              href="https://admin.schmidt-global.nl/wp-content/uploads/2021/03/Schmidt-packing-tips-3.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Packing Tips
            </a>
          </Li>
        )}
      </List>
    )}

    {menu.description && (
      <Content className="mt-3">
        <ParseContent content={menu.description} />
      </Content>
    )}
  </>
)

export default Footer
